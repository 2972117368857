* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}
body {
  font-family: sans-serif;
  padding: 20px;
}

.flashcards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.flashcards div {
  border: 1px solid #e7e7e7;
  background-color: powderblue;
  border-radius: 15px;
  aspect-ratio: 2 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  border: 1px solid black;
}

div.selected {
  border: 1px solid #e03131;
  background-color: #e03131;
  color: #fff;
  font-weight: bold;
}
